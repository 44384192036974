import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";
import Input from "../ContactForm/input";
import Phone from "../ContactForm/Phone";
import Textarea from "../ContactForm/Textarea";

import icon from '../../../assets/images/arrow-right.svg';

import classes from './ContactBlock.module.scss';

const ContactBlock = () => {
    const { t } = useTranslation();
    const { hostname } = document.location;

    const [name, setName] = useState('');
    const [country, setCountry] = useState('');
    // eslint-disable-next-line
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [additional, setAdditional] = useState('');

    const submit = () => {
        alert('Submit');
    };

    return (
        <section className={classes.container}>
            <div className={classes.wrap}>
                <div className={classes.item}>
                <div className={classes.formWrap}>
                    <h2 className={`${classes.title} font-36`}>
                        {t('about-form-title')}
                    </h2>
                    <div className={classes.form}>
                        <div className={classes.inputs}>
                            <div className={classes.group}>
                                <Input
                                    value={name}
                                    setValue={setName}
                                    valid={true}
                                    type="text"
                                    placeholder={t('about-form-name')}
                                />
                            </div>
                            <div className={classes.group}>
                                <Input
                                    value={country}
                                    setValue={setCountry}
                                    valid={true}
                                    type="text"
                                    placeholder={t('about-form-country')}
                                />
                            </div>
                            <div className={classes.group}>
                                <Phone
                                    setValue={setPhone}
                                    valid={true}
                                />
                            </div>
                            <div className={classes.group}>
                                <Textarea
                                    value={email}
                                    setValue={setEmail}
                                    valid={true}
                                    type="email"
                                    placeholder={t('about-form-textarea')}
                                />
                            </div>
                        </div>
                        <div className={classes.submit} onClick={submit}>
                            {t('about-form-btntext')}
                            <i className={classes.icon}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <   path d="M16.2539 10.1568L1.01874 18.9528L1.01875 1.36075L16.2539 10.1568Z" fill="#202020"/>
                                </svg>
                            </i>
                        </div>
                    </div>
                </div>
                </div>
                <div className={classes.contacts}>
                    <div className={classes.item}>
                        <p className={classes.itemTitle}>
                            <Trans>
                                {t('about-form-page-title')}
                            </Trans>
                        </p>
                        <p className={classes.itemText}>
                            <Trans>
                                {t('about-form-page-text')}
                            </Trans>
                        </p>
                        <div className={classes.btnWrap}>
                            <Button text={t('main.first.btn')} icon={icon} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactBlock;