import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../components//GlodalComponents/Header/Header';
import Footer from '../components/GlodalComponents/Footer/Footer';
import FirstDisplay from '../components/Main/FirstDisplay/component';
import SecondDisplay from '../components/Main/SecondDisplay/component';
import ThirdDisplay from '../components/Main/ThirdDisplay/component';
import FourDisplay from '../components/Main/FourDisplay/component';

function Main() {

  return (
    <HelmetProvider>
      <Helmet>
        <title>GSB Capital LTD</title>
        <meta name="title" content="GSB Capital LTD | Your Premier Trading Platform for Smart Investments" />
        <meta property="og:title" content="GSB Capital LTD | Your Premier Trading Platform for Smart Investments" />
        <meta property="twitter:title" content="GSB Capital LTD | Your Premier Trading Platform for Smart Investments" />

        <meta name="description" content="Explore the power of seamless trading with GSB Capital LTD. Unleash opportunities, stay informed, and make informed investment decisions on our dynamic Main Page." />
        <meta property="og:description" content="Explore the power of seamless trading with GSB Capital LTD. Unleash opportunities, stay informed, and make informed investment decisions on our dynamic Main Page." />
        <meta property="twitter:description" content="Explore the power of seamless trading with GSB Capital LTD. Unleash opportunities, stay informed, and make informed investment decisions on our dynamic Main Page." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='ovf-hidden'>
        <Header />

        <FirstDisplay />
        <SecondDisplay />
        <ThirdDisplay />
        <FourDisplay />
        

        <Footer />
      </div>
    </HelmetProvider>
  )
}

export default Main;
