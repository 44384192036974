import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../components/GlodalComponents/Header/Header';
import Footer from '../components/GlodalComponents/Footer/Footer';
import FirstD from '../components/AccountTypes/FirstD/component';
import TableTabs from '../components/AccountTypes/TableTabs/component';
import BottomBlock from '../components/Main/FourDisplay/component';

function AccounTypes() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>GSB Capital LTD · Account types</title>
        <meta name="title" content="GSB Capital LTD Account Types | Tailored Solutions for Every Investor" />
        <meta property="og:title" content="GSB Capital LTD Account Types | Tailored Solutions for Every Investor" />
        <meta property="twitter:title" content="GSB Capital LTD Account Types | Tailored Solutions for Every Investor" />

        <meta name="description" content="Choose from our diverse account types at GSB Capital LTD to match your unique investment style. Whether you're a beginner or seasoned pro, we have the right account to fuel your financial ambitions." />
        <meta property="og:description" content="Choose from our diverse account types at GSB Capital LTD to match your unique investment style. Whether you're a beginner or seasoned pro, we have the right account to fuel your financial ambitions." />
        <meta property="twitter:description" content="Choose from our diverse account types at GSB Capital LTD to match your unique investment style. Whether you're a beginner or seasoned pro, we have the right account to fuel your financial ambitions." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='ovf-hidden account-page'>
        <Header />
        <FirstD />
        <TableTabs />
        <BottomBlock />
        <Footer />
      </div>
    </HelmetProvider>
  )
  
}

export default AccounTypes;
