import React from 'react';
import { useTranslation } from "react-i18next";
import { Fade } from "react-reveal";
import image from '../../../assets/images/homepage/third.webp';
import arrow from '../../../assets/images/arrow-orange.svg';

import './style.scss';

function ThirdDisplay() {
  const { t } = useTranslation();

  return (
    <div className='third-display'>
      <div className='container'>
        <div className='item'>
          <Fade left>
            <div>
              <img src={image} alt='' />
            </div>
          </Fade>
        </div>
        <div className='item pad'>
          <Fade bottom>
            <div className='content'>
              <div className='main-title'>
                {t('main.third.text-1')}
              </div>
              <div className='main-subtitle'>{t('main.third.text-2')}</div>
              <div className='main-text with-icon'>
                <i className='icon'>
                  <img src={arrow} alt='' />
                </i>
                {t('main.third.text-3')}
              </div>
              <div className='main-text with-icon'>
                <i className='icon'>
                  <img src={arrow} alt='' />
                </i>
                {t('main.third.text-4')}
              </div>
              <div className='main-text with-icon'>
                <i className='icon'>
                  <img src={arrow} alt='' />
                </i>
                {t('main.third.text-5')}
              </div>
              <div className='main-text with-icon'>
                <i className='icon'>
                  <img src={arrow} alt='' />
                </i>
                {t('main.third.text-6')}
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  )
}

export default ThirdDisplay;
