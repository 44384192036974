import React from 'react';
import { Trans, useTranslation } from "react-i18next";
import { Zoom, Fade } from "react-reveal";

import image from '../../../assets/images/faqs/image0.webp';

import './style.scss';

const FaqsFirst = () => {
  const { t } = useTranslation();

  return (
    <div className='faqs-first'>
      <div className='container'>
        <div className='left'>
          <Zoom center>
            <div>
              <Trans><div className='main-title'>{t('faq-0-title')}</div></Trans>
              <div className='main-text'>{t('faq-0-text')}</div>
            </div>
          </Zoom>
        </div>
      </div>
      
      <div className='img-wrap absolute'>
        <Fade right>
          <img src={image} alt='' />
        </Fade>
      </div>
    </div>
  );
};

export default FaqsFirst;
