import React, { useState } from 'react';
import { Trans, useTranslation } from "react-i18next";
import { Zoom } from "react-reveal";
import Button from '../../GlodalComponents/Button/Button';

import arrowblack from '../../../assets/images/arrow-right.svg';

import './style.scss';

const SecondDisplay = () => {
  const { t } = useTranslation();

  const items = t('third-2-list', { returnObjects: true });

  return (
    <div className='services-sec'>
      <div className="container">
        <div className='item'>
          <Zoom center>
            <Trans><div className='main-title'>{t('third-2-title')}</div></Trans>
            <div className='main-text'>{t('third-2-text')}</div>
            <Button text={t('main.first.btn')} icon={arrowblack} />
          </Zoom>
        </div>
        
        <div className='item'>
          <Zoom>
            <div>
              <div className='list-wrap'>
                <div className='main-title'>{t('third-2-list-title')}</div>
                {items.map((item, index) => (
                  <div className='with-disk' key={index}>
                    {t(`${item.title}`)}
                  </div>
                ))}
              </div>
            </div>
          </Zoom>
        </div>
      </div>
    </div>
  );
};

export default SecondDisplay;
