import _ from 'lodash';
import React, {useState, useEffect} from 'react';
import { Link, useLocation, useNavigate} from 'react-router-dom';
import { useParams } from "react-router-dom";
import Button from '../Button/Button';
import { useTranslation } from "react-i18next";
import Dropdown from 'react-dropdown';
import Cookies from "js-cookie";
import logo from '../../../assets/images/logo.png';
import './style.scss';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const {pathname} = useLocation();
  const { lang } = useParams();
  const navigate = useNavigate();

  const toggleMenu = () => setMenuOpen(!menuOpen);

  const setLocale = (lang) => {
    i18n.changeLanguage(lang);
    navigate(`/${lang}/${_.last(pathname.split('/'))}`);
  };

  const { t, i18n } = useTranslation();

  const languages = [
      { value: 'en', label: 'EN' },
      { value: 'ru', label: 'RU' },
      { value: 'pl', label: 'PL' },
      { value: 'de', label: 'DE' },
  ]

  const storedLang = Cookies.get("i18next");

  const [currentLang, setCurrentLang] = useState(storedLang || 'en');

  useEffect(() => {
    window.scrollTo(0, 0);
    
    if (storedLang && storedLang !== currentLang) {
        setCurrentLang(storedLang);
        i18n.changeLanguage(storedLang);
    }
    // eslint-disable-next-line
    document.body.setAttribute('dir', storedLang === 'ar' ? 'rtl' : 'ltr');
  }, [storedLang]);  

  const handleChangeLang = (selectedLang) => {
      setCurrentLang(selectedLang.value);
      i18n.changeLanguage(selectedLang.value);
      Cookies.set("i18next", selectedLang.value);
      navigate(`/${selectedLang.value}/${_.last(pathname.split('/'))}`);
  };

  useEffect(() => {
    const langs = _.dropRight(i18n.options.supportedLngs);
    if (lang === undefined || !langs.includes(lang)) {
      setLocale("en");
    } else {
      setLocale(lang);
    }
    // eslint-disable-next-line
  }, []);

  const headerLinks = t('links', { returnObjects: true });

  return <header className="header">
          <div className='container'>
            <div className={`nav ${menuOpen ? 'open' : ''}`}>
              <Link to={`/${storedLang}/`}>
                <img src={logo} alt="Logo" className="header-logo" />
              </Link>
              <nav className="header-menu">
                <ul>
                  {headerLinks.map((link, index) => (
                    <li key={index}>
                      <Link to={`/${storedLang}/${link.link_href}`} className={`nav-item ${_.last(pathname.split('/')) === link.link_href ? 'active' : ''}`}>{link.link_title}</Link>
                    </li>
                  ))}
                </ul>

                <div className='flex'>
                    <Dropdown
                      options={languages}
                      placeholder="EN"
                      onChange={handleChangeLang}
                      value={languages.find(lang => lang.value === currentLang)}
                    />
                    <Button text={t('main.first.btn-login')} login></Button>
                </div>
              </nav>
            </div>
            <div className='mobile-content'>
              <a href='/' className='header-link'>
                <img src={logo} alt="Logo" className="header-logo" />
              </a>
              <Dropdown
                options={languages}
                placeholder="EN"
                onChange={handleChangeLang}
                value={languages.find(lang => lang.value === currentLang)}
              />
              <div className={`burger ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </header>;
};

export default Header;