import React from 'react';
import { useTranslation } from "react-i18next";
import { Fade } from "react-reveal";
import image from '../../../assets/images/homepage/line-bg.webp';

import './style.scss';

function SecondDisplay() {
  const { t } = useTranslation();

  return (
    <div className='second-display'>
      <div className='line'>
        <div className='line-container'>
          <div className='w-57'>
            <Fade left duration={2500}>
              <div>
                <div className='content'>
                  <div className='main-title'>{t('main.second.title-0')}</div>
                  <div className='main-text'>{t('main.second.text-0')}</div>
                </div>
              </div>
            </Fade>
          </div>
          <div className='w-43'>
            <Fade right duration={3000}>
              <div>
                <div className='content'>
                    <div className='main-title'>{t('main.second.title-1')}</div>
                    <div className='main-text'>{t('main.second.text-1')}</div>
                  </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
      <div className='line'>
        <div className='line-container'>
          <div className='w-43'>
            <Fade left duration={3000}>
              <div className='content'>
                <div>
                  <div className='main-title'>{t('main.second.title-2')}</div>
                  <div className='main-text'>{t('main.second.text-2')}</div>
                </div>
              </div>
            </Fade>
          </div>
          <div className='w-57'>
            <div className='absolute img-wrap'>
              <img src={image} alt='' />
            </div>
            <Fade right duration={2500}>
              <div>
                <div className='content'>
                  <div className='main-title'>{t('main.second.title-3')}</div>
                  <div className='main-text'>{t('main.second.text-3')}</div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SecondDisplay;
