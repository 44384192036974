import React, { useState } from 'react';
import { Trans, useTranslation } from "react-i18next";
import { Zoom } from "react-reveal";
import Button from '../../GlodalComponents/Button/Button';

import image0 from '../../../assets/images/services/image0.webp';
import arrowblack from '../../../assets/images/arrow-right.svg';

import './style.scss';

const ServicesFirst = () => {
  const { t } = useTranslation();

  const items = t('third-1-list', { returnObjects: true });

  return (
    <div className='services-first'>
      <div className="container">
        <div className='item'>
          <Zoom center>
            <Trans><div className='main-title'>{t('third-1-title')}</div></Trans>
            <div className='main-text'>{t('third-1-text')}</div>
            <div className='img-wrap desk_only'>
              <img src={image0} alt='' />
            </div>
          </Zoom>
        </div>
        
        <div className='item'>
          <Zoom>
            <div>
              <div className='list-wrap'>
                {items.map((item, index) => (
                  <div className='with-disk' key={index}>
                    {t(`${item.title}`)}
                  </div>
                ))}
              </div>
              <Button text={t('main.first.btn')} icon={arrowblack} />
              <div className='img-wrap mob_only'>
                <img src={image0} alt='' />
              </div>
            </div>
          </Zoom>
        </div>
      </div>
    </div>
  );
};

export default ServicesFirst;
