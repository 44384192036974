import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../components//GlodalComponents/Header/Header';
import Footer from '../components/GlodalComponents/Footer/Footer';
import ImageText from '../components/AboutUs/ImageText/component';
import ContactBlock from '../components/AboutUs/ContactBlock/component';

import image0 from '../assets/images/aboutus/image0.webp';
import image1 from '../assets/images/aboutus/image1.webp';
import image2 from '../assets/images/aboutus/image2.webp';
import icon from '../assets/images/aboutus/heart.svg';



const datafirst = [
  {
    title: 'about-0-title',
    subtitle: 'about-0-subtitle',
    text: 'about-0-text',
    img: image0,
  },
  {
    title: 'about-1-title',
    subtitle: 'about-1-subtitle',
    text: 'about-1-text',
    img: image1,
  }
];

const datasecond = [
  {
    title: 'about-2-title',
    text: 'about-2-text',
    img: image2,
    icon: icon,
  }
];

function AboutUs() {
  return (
    <HelmetProvider>
      <Helmet>
      <title>GSB Capital LTD · About Us</title>
        <meta name="title" content="About GSB Capital LTD | Pioneering Excellence in Online Trading" />
        <meta property="og:title" content="About GSB Capital LTD | Pioneering Excellence in Online Trading" />
        <meta property="twitter:title" content="About GSB Capital LTD | Pioneering Excellence in Online Trading" />

        <meta name="description" content="​​Learn about the vision, values, and commitment driving GSB Capital LTD. Discover why we are the preferred choice for traders seeking a reliable, innovative, and customer-centric online trading platform." />
        <meta property="og:description" content="​​Learn about the vision, values, and commitment driving GSB Capital LTD. Discover why we are the preferred choice for traders seeking a reliable, innovative, and customer-centric online trading platform." />
        <meta property="twitter:description" content="​​Learn about the vision, values, and commitment driving GSB Capital LTD. Discover why we are the preferred choice for traders seeking a reliable, innovative, and customer-centric online trading platform." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='ovf-hidden about-page'>
        <Header />

        <ImageText data={datafirst} />
        <ContactBlock />
        <ImageText data={datasecond} />

        <Footer />
      </div>
    </HelmetProvider>
  )
  
}

export default AboutUs;
