import React from "react";
import classes from './Input.module.scss';

const Input = ({
    value, setValue,
    valid,
    type,
    placeholder = "",
}) => {
    return (
        <input
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            className={`${classes.input} ${!valid && classes.error}`}
        />
    );
};

export default Input;