import React from 'react';
import { Trans, useTranslation } from "react-i18next";
import { Zoom, Reveal } from "react-reveal";

import './style.scss';

const FaqsFirst = ({data}) => {
  const { t } = useTranslation();

  return (
    <div className='about-first'>
      {data.map((item, index) => (
        <div className='row' key={index}>
          <Zoom>
            <div className={`item ${item.icon && 'orange'}`}>
              {item.icon && <i className="icon"><img src={item.icon} alt="icon" /></i>}
              {item.title && <div className='main-title'>{t(`${item.title}`)}</div>}
              {item.subtitle && <div className='main-subtitle'>{t(`${item.subtitle}`)}</div>}
              {item.text && <div className='main-text'>{t(`${item.text}`)}</div>}
            </div>
          </Zoom>
          {item.img && 
            <div className='item img-wrap'>
              <Reveal effect="zoomOut">
                <div>
                  <img src={item.img} alt='' />
                </div>
              </Reveal>
            </div>
          }
        </div>
      ))}
    </div>
  );
};

export default FaqsFirst;
