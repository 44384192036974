import React from 'react';
import PropTypes from 'prop-types';
import { useParams, Link } from "react-router-dom";
import './style.scss';

const Button = ({ text, icon, link, login }) => {
  const { lang } = useParams();

  return (
    <Link 
      className="custom-button"
      to={!link
        ? `${process.env.REACT_APP_CABINET_URL}/${login ? 'login' : 'signup'}?lang=${lang}`
        : `/${lang}/${link}`
      }
    >
      {text}
      {icon && <i className="icon"><img src={icon} alt="icon" /></i>}
    </Link>
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

export default Button;