import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../components//GlodalComponents/Header/Header';
import Footer from '../components/GlodalComponents/Footer/Footer';
import ProductBlock from '../components/ProductPage/component';


function Products() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>GSB Capital LTD · Products</title>
        <meta name="title" content="GSB Capital LTD Products | Diversify Your Portfolio with Confidence" />
        <meta property="og:title" content="GSB Capital LTD Products | Diversify Your Portfolio with Confidence" />
        <meta property="twitter:title" content="GSB Capital LTD Products | Diversify Your Portfolio with Confidence" />

        <meta name="description" content="Discover a range of cutting-edge financial products on GSB Capital LTD. From stocks to crypto, explore opportunities that align with your investment goals and secure your financial future." />
        <meta property="og:description" content="Discover a range of cutting-edge financial products on GSB Capital LTD. From stocks to crypto, explore opportunities that align with your investment goals and secure your financial future." />
        <meta property="twitter:description" content="Discover a range of cutting-edge financial products on GSB Capital LTD. From stocks to crypto, explore opportunities that align with your investment goals and secure your financial future." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='product-page'>
        <Header />
        <ProductBlock />
        <Footer />
      </div>
    </HelmetProvider>
  )
  
}

export default Products;
