import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import Button from '../GlodalComponents/Button/Button';
import Accordion from '../GlodalComponents/Accordion/component';

import image0 from '../../assets/images/products/main-0.webp';
import image1 from '../../assets/images/products/main-1.webp';
import image2 from '../../assets/images/products/main-2.webp';
import image3 from '../../assets/images/products/main-3.webp';
import image4 from '../../assets/images/products/main-4.webp';
import sub0 from '../../assets/images/products/sub-0.webp';
import sub1 from '../../assets/images/products/sub-1.webp';
import sub2 from '../../assets/images/products/sub-2.webp';
import sub4 from '../../assets/images/products/sub-4.webp';
import arrow from '../../assets/images/arrow-orange.svg';
import arrowblack from '../../assets/images/arrow-right.svg';

import './style.scss';

const tabContent = [
  {
    id: 0,
    title: 'tabs-0',
  },
  {
    id: 1,
    title: 'tabs-1',
  },
  {
    id: 2,
    title: 'tabs-2',
  },
  {
    id: 3,
    title: 'tabs-3',
  },
  {
    id: 4,
    title: 'tabs-4',
  },
];

const TabComponent = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { t } = useTranslation();

  const handleTabClick = (id) => {
    setActiveTab(id);
    setDropdownOpen(false);
  };

  const dropdownTabs = tabContent.filter(tab => tab.id !== activeTab);

  const items1 = t('tabs-1-acc', { returnObjects: true });
  const items2 = t('tabs-2-acc', { returnObjects: true });

  return (
    <div className='product-tabs'>
      <div className="container">
      <div className="tab-dropdown">
        <button className='selected' onClick={() => setDropdownOpen(!dropdownOpen)}>
          <i className='icon'>
            <img src={arrow} alt='' />
          </i>
          {t(`${tabContent.find(tab => tab.id === activeTab).title}`)}
        </button>
        {dropdownOpen && (
          <div className="dropdown-options">
            {dropdownTabs.map(tab => (
              <button key={tab.id} onClick={() => handleTabClick(tab.id)}>
                <i className='icon'>
                  <img src={arrow} alt='' />
                </i>
                {t(`${tab.title}`)}
              </button>
            ))}
          </div>
        )}
      </div>

      <div className="tab-buttons">
        {tabContent.map(tab => (
          <button
            key={tab.id}
            className={tab.id === activeTab ? 'active' : ''}
            onClick={() => handleTabClick(tab.id)}
          >
            <i className='icon'>
              <img src={arrow} alt='' />
            </i>
            {t(`${tab.title}`)}
          </button>
        ))}
      </div>

      <div className="tab-content">
        <div className={`content-item ${0 === activeTab ? 'active' : ''}`}>
          <div className='content'>
            <div className='main-img'>
              <img src={image0} alt='' />
              <div className='sign'>{t('tabs-0-title')}</div>
            </div>

            <div className='main-text'>{t('tabs-0-text')}</div>

            <div className='block-icon'>
              <div className='with-icon'>
                <i className='icon'>
                  <img src={arrow} alt='' />
                </i>
                {t('tabs-0-icons-0')}
              </div>
              <div className='with-icon'>
                <i className='icon'>
                  <img src={arrow} alt='' />
                </i>
                {t('tabs-0-icons-1')}
              </div>
              <div className='with-icon'>
                <i className='icon'>
                  <img src={arrow} alt='' />
                </i>
                {t('tabs-0-icons-2')}
              </div>
              <div className='with-icon'>
                <i className='icon'>
                  <img src={arrow} alt='' />
                </i>
                {t('tabs-0-icons-3')}
              </div>
            </div>

            <div className='bot-block'>
              <div className='left'>
                <div className='bot-title'>{t('tabs-0-acc-title')}</div>
                <Accordion title='tabs-0-acc' />
              </div>
              <div className='right img-wrap'>
                <div className='mob_only'><Button text={t('main.first.btn')} icon={arrowblack} /></div>
                <img className='sub-img' src={sub0} alt='' />
              </div>
            </div>
            <div className='desk_only'><Button text={t('main.first.btn')} icon={arrowblack} /></div>
          </div>
        </div>

        <div className={`content-item ${1 === activeTab ? 'active' : ''}`}>
          <div className='content'>
            <div className='main-img'>
              <img src={image1} alt='' />
              <div className='sign'>{t('tabs-1-title')}</div>
            </div>

            <div className='main-text'>{t('tabs-1-text')}</div>

            <div className='block-icon'>
              <div className='with-icon'>
                <i className='icon'>
                  <img src={arrow} alt='' />
                </i>
                {t('tabs-1-icons-0')}
              </div>
              <div className='with-icon'>
                <i className='icon'>
                  <img src={arrow} alt='' />
                </i>
                {t('tabs-1-icons-1')}
              </div>
              <div className='with-icon'>
                <i className='icon'>
                  <img src={arrow} alt='' />
                </i>
                {t('tabs-1-icons-2')}
              </div>
              <div className='with-icon'>
                <i className='icon'>
                  <img src={arrow} alt='' />
                </i>
                {t('tabs-1-icons-3')}
              </div>
            </div>

            <div className='bot-block'>
              <div className='left'>
                <div className='bot-title'>{t('tabs-1-acc-title')}</div>
                <div className='list-wrap'>
                  {items1.map((item, index) => (
                    <div className='with-disk' key={index}>
                      {t(`${item.title}`)}
                    </div>
                  ))}
                </div>
              </div>
              <div className='right img-wrap'>
                <div className='mob_only'><Button text={t('main.first.btn')} icon={arrowblack} /></div>
                <img className='sub-img' src={sub1} alt='' />
              </div>
            </div>

            <div className='desk_only'><Button text={t('main.first.btn')} icon={arrowblack} /></div>
          </div>
        </div>

        <div className={`content-item ${2 === activeTab ? 'active' : ''}`}>
          <div className='content'>
            <div className='main-img'>
              <img src={image2} alt='' />
              <div className='sign'>{t('tabs-2-title')}</div>
            </div>

            <div className='main-text'>{t('tabs-2-text')}</div>

            <div className='block-icon'>
              <div className='with-icon'>
                <i className='icon'>
                  <img src={arrow} alt='' />
                </i>
                {t('tabs-2-icons-0')}
              </div>
              <div className='with-icon'>
                <i className='icon'>
                  <img src={arrow} alt='' />
                </i>
                {t('tabs-2-icons-1')}
              </div>
              <div className='with-icon'>
                <i className='icon'>
                  <img src={arrow} alt='' />
                </i>
                {t('tabs-2-icons-2')}
              </div>
              <div className='with-icon'>
                <i className='icon'>
                  <img src={arrow} alt='' />
                </i>
                {t('tabs-2-icons-3')}
              </div>
            </div>

            <div className='bot-block'>
              <div className='left'>
                <div className='bot-title'>{t('tabs-2-acc-title')}</div>
                <div className='list-wrap'>
                  {items2.map((item, index) => (
                    <div className='with-disk' key={index}>
                      {t(`${item.title}`)}
                    </div>
                  ))}
                </div>
              </div>
              <div className='right img-wrap'>
                <div className='mob_only'><Button text={t('main.first.btn')} icon={arrowblack} /></div>
                <img className='sub-img' src={sub2} alt='' />
              </div>
            </div>
            <div className='desk_only'><Button text={t('main.first.btn')} icon={arrowblack} /></div>
          </div>
        </div>

        <div className={`content-item ${3 === activeTab ? 'active' : ''}`}>
          <div className='content'>
            <div className='main-img'>
              <img src={image3} alt='' />
              <div className='sign'>{t('tabs-2-title')}</div>
            </div>

            <div className='main-text'>{t('tabs-2-text')}</div>

            <div className='block-icon'>
              <div className='with-icon'>
                <i className='icon'>
                  <img src={arrow} alt='' />
                </i>
                {t('tabs-2-icons-0')}
              </div>
              <div className='with-icon'>
                <i className='icon'>
                  <img src={arrow} alt='' />
                </i>
                {t('tabs-2-icons-1')}
              </div>
              <div className='with-icon'>
                <i className='icon'>
                  <img src={arrow} alt='' />
                </i>
                {t('tabs-2-icons-2')}
              </div>
              <div className='with-icon'>
                <i className='icon'>
                  <img src={arrow} alt='' />
                </i>
                {t('tabs-2-icons-3')}
              </div>
            </div>

            <Button text={t('main.first.btn')} icon={arrowblack} />
          </div>
        </div>

        <div className={`content-item ${4 === activeTab ? 'active' : ''}`}>
          <div className='content'>
            <div className='main-img'>
              <img src={image4} alt='' />
              <div className='sign'>{t('tabs-4-title')}</div>
            </div>

            <div className='main-text'>{t('tabs-4-text')}</div>

            <div className='block-icon'>
              <div className='with-icon'>
                <i className='icon'>
                  <img src={arrow} alt='' />
                </i>
                {t('tabs-4-icons-0')}
              </div>
              <div className='with-icon'>
                <i className='icon'>
                  <img src={arrow} alt='' />
                </i>
                {t('tabs-4-icons-1')}
              </div>
              <div className='with-icon'>
                <i className='icon'>
                  <img src={arrow} alt='' />
                </i>
                {t('tabs-4-icons-2')}
              </div>
              <div className='with-icon'>
                <i className='icon'>
                  <img src={arrow} alt='' />
                </i>
                {t('tabs-4-icons-3')}
              </div>
            </div>

            <div className='bot-block'>
              <div className='left'>
                <div className='bot-title'>{t('tabs-4-acc-title')}</div>
                <Accordion title='tabs-4-acc' />
              </div>
              <div className='right img-wrap'>
                <div className='mob_only'><Button text={t('main.first.btn')} icon={arrowblack} /></div>
                <img className='sub-img' src={sub4} alt='' />
              </div>

            </div>
            <div className='desk_only'><Button text={t('main.first.btn')} icon={arrowblack} /></div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default TabComponent;
