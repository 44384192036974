import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../components//GlodalComponents/Header/Header';
import Footer from '../components/GlodalComponents/Footer/Footer';
import FaqsFirst from '../components/FAQs/FirstDisplay/component';
import TabsAccoridon from '../components/FAQs/TabsAccordion/component';

function FAQs() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>GSB Capital LTD · FAQs</title>
        <meta name="title" content="GSB Capital LTD FAQs | Your Guide to Seamless Trading" />
        <meta property="og:title" content="GSB Capital LTD FAQs | Your Guide to Seamless Trading" />
        <meta property="twitter:title" content="GSB Capital LTD FAQs | Your Guide to Seamless Trading" />

        <meta name="description" content="Get answers to common questions and explore helpful insights in the GSB Capital LTD FAQs. Empower yourself with knowledge to make informed decisions and optimize your trading experience." />
        <meta property="og:description" content="Get answers to common questions and explore helpful insights in the GSB Capital LTD FAQs. Empower yourself with knowledge to make informed decisions and optimize your trading experience." />
        <meta property="twitter:description" content="Get answers to common questions and explore helpful insights in the GSB Capital LTD FAQs. Empower yourself with knowledge to make informed decisions and optimize your trading experience." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='ovf-hidden faq-page'>
        <Header />
        
        <FaqsFirst />
        <TabsAccoridon />
      
        <Footer />
      </div>
    </HelmetProvider>
  )
  
}

export default FAQs;
