import React from 'react';
import { Trans, useTranslation } from "react-i18next";
import { Zoom, Fade } from "react-reveal";

import image from '../../../assets/images/actypes.webp';
import imageMob from '../../../assets/images/types-mob.webp';

import './style.scss';

const AcTypesFirst = () => {
  const { t } = useTranslation();

  return (
    <div className='types-first'>
      <div className='left'>
        <Zoom center>
          <div>
            <Trans><div className='main-title'>{t('actypes-1-title0')}</div></Trans>
            <Trans><div className='main-subtitle'>{t('actypes-1-title1')}</div></Trans>
            <div className='main-text'>{t('actypes-1-text')}</div>
          </div>
        </Zoom>
      </div>
      
      <Fade right>
        <div className='img-wrap'>
          <img className='desk_only' src={image} alt='' />
          <img className='mob_only' src={imageMob} alt='' />
        </div>
      </Fade>
    </div>
  );
};

export default AcTypesFirst;
