import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../components//GlodalComponents/Header/Header';
import Footer from '../components/GlodalComponents/Footer/Footer';
import FirstD from '../components/ServicesPage/FirstDisplay/component';
import SecondD from '../components/ServicesPage/SecondDisplay/component';
import ThirdD from '../components/ServicesPage/ThirdDisplay/component';
import PaymentMethods from '../components/ServicesPage/PaymentMethods/component';


function Services() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>GSB Capital LTD · Services</title>
        <meta name="title" content="GSB Capital LTD Services | Elevate Your Trading Experience" />
        <meta property="og:title" content="GSB Capital LTD Services | Elevate Your Trading Experience" />
        <meta property="twitter:title" content="GSB Capital LTD Services | Elevate Your Trading Experience" />

        <meta name="description" content="Experience unparalleled trading services with GSB Capital LTD. Our comprehensive range of services ensures you have the tools, support, and insights you need to navigate the markets successfully." />
        <meta property="og:description" content="Experience unparalleled trading services with GSB Capital LTD. Our comprehensive range of services ensures you have the tools, support, and insights you need to navigate the markets successfully." />
        <meta property="twitter:description" content="Experience unparalleled trading services with GSB Capital LTD. Our comprehensive range of services ensures you have the tools, support, and insights you need to navigate the markets successfully." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='ovf-hidden services-page'>
        <Header />
        <FirstD />
        <SecondD />
        <ThirdD />
        <PaymentMethods />
        <Footer />
      </div>
    </HelmetProvider>
  )
  
}

export default Services;
