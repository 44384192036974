import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { Trans, useTranslation } from "react-i18next";
import Button from '../../GlodalComponents/Button/Button';
import arrow from '../../../assets/images/arrow-right.svg';
import 'swiper/css';
import 'swiper/css/pagination';

const SwiperSlider = ({slides}) => {
  const { t, i18n } = useTranslation();

  return (
    <Swiper
      modules={[Autoplay]}
      spaceBetween={50}
      slidesPerView={1}
      pagination={{ clickable: true }}
      autoplay={{ delay: 3000, disableOnInteraction: false }}
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={index}>
          <div className='main-title'>
            <Trans>
              {t(`${slides[index].title}`)}
            </Trans>
          </div>
          <div className='main-text'>{t(`${slides[index].text}`)}</div>
          <Button text={t('main.first.btn')} icon={arrow} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SwiperSlider;
