import React from 'react';
import SwiperSlider from '../MainSlider/component';
import { Fade } from "react-reveal";
import mainbg from '../../../assets/images/homepage/main-bg.webp';
import image1 from '../../../assets/images/homepage/ph-left.webp';
import image2 from '../../../assets/images/homepage/ph-right.webp';

import './style.scss';


function FirstDisplay() {

  const slides = [
    {
      title: "main.first.slider-0-0",
      text: "main.first.slider-0-1",
    },
    {
      title: "main.first.slider-1-0",
      text: "main.first.slider-1-1",
    },
    {
      title: "main.first.slider-2-0",
      text: "main.first.slider-2-1",
    },
  ]

  return (
    <div className='first-display'>
      <div className='absolute img-wrap'>
        <img src={mainbg} alt='' />
      </div>
      <div className='container'>
        <div className='left'>
          <SwiperSlider slides={slides} />
        </div>
        <div className='right'>
          <Fade left bottom duration={1500}>
            <img src={image1} className='image-1' alt='' />
          </Fade>
          <Fade right bottom duration={1500}>
            <img src={image2} className='image-2' alt='' />
          </Fade>
        </div>
      </div>

    </div>
  )
}

export default FirstDisplay;
