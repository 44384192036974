import React, { useState } from 'react';
import { Trans, useTranslation } from "react-i18next";
import { Zoom } from "react-reveal";
import Button from '../../GlodalComponents/Button/Button';

import image from '../../../assets/images/services/image1.webp';
import arrowblack from '../../../assets/images/arrow-right.svg';

import './style.scss';

const SecondDisplay = () => {
  const { t } = useTranslation();

  const items = t('third-2-list', { returnObjects: true });

  return (
    <div className='services-third'>
      <div className='first'>
        <div className="container">
          <div className='item'>
              <Trans><div className='main-title'>{t('third-3-title')}</div></Trans>
              <div className='main-text'>{t('third-3-text')}</div>
              <Button text={t('main.first.btn')} icon={arrowblack} />
          </div>
          <div className='item img-wrap'>
            <img src={image} alt='' />
          </div>
        </div>
      </div>
      <div className='second'>
        <div className='main-title'>{t('third-3-title1')}</div>
        <div className='main-text'>{t('third-3-text1')}</div>
      </div>
    </div>
  );
};

export default SecondDisplay;
