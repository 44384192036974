import React from 'react';
import { useTranslation } from "react-i18next";
import logo from '../../../assets/images/logo.png';
import './style.scss';

const Footer = () => {
  const { t } = useTranslation();
  const { hostname } = document.location;
  
  return (
    <footer className='footer'>
      <div className='container'>
        <div className='footer_content'>
          <div className="left-side">
            <div className='logo-wrap'>
              <img src={logo} alt="Logo" className="header-logo" />
            </div>
            <div className='footer-nav'>
                <div className='item-block'>
                  <div className='nav-title'>{t('footer.link-1')}</div>
                  <div className='nav-title opacity'>Adress: Office 01, Level 9, Gate District, Gate Building, DIFC, PO Box 938542, Dubai, UAE</div>
                </div>
                <div className='item-block'>
                  <div className='nav-title'>{t('footer.link-2')}</div>
                  <a href={`mailto:support@${hostname}`} className='nav-title opacity'>
                    support@{hostname}
                  </a>
                </div>
                <div className='item-block'>
                  <a href="/docs/PRIVACY_POLICY.pdf" target="_blank" className='nav-title'>{t('footer.privacy')}</a>
                </div>
                <div className='item-block'>
                  <a href="/docs/Terms_and_Conditions.pdf" target="_blank" className='nav-title'>{t('footer.terms')}</a>
                </div>
                <div className='item-block'>
                  <a href="/docs/ANTI-MONEY_LAUNDERING_POLICY.pdf" target="_blank" className='nav-title'>{t('footer.aml')}</a>
                </div>
            </div>
          </div>
          <div className='right-side'>
            <div className='nav-title'>{t('footer.title')}</div>
            <div className='text'>
              <p>
                {t('footer.text')}
              </p>
              <p>
                {t('footer.text-1')}
              </p>
            </div>
            <div className='copyright'>{t('footer.copyright')}</div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;