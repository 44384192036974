import React, { useState } from 'react';
import { Trans, useTranslation } from "react-i18next";
import arrow from '../../../assets/images/acc-arrow.svg';
import './style.scss';

const Accordion = ({title}) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const { t } = useTranslation();

  const onTitleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const items = t(`${title}`, { returnObjects: true });

  const renderedItems = items.map((item, index) => {
    const active = index === activeIndex ? 'active' : '';

    return (
      <div key={index} className={`item ${active}`} >
        <div 
          className={`title ${active}`} 
          onClick={() => onTitleClick(index)}
        >
          {item.title}
          <div className='icon'>
            <span></span>
          </div>
        </div>
        <div className={`content ${active}`}>
          <Trans>
            {item.content}
          </Trans>
        </div>
      </div>
    );
  });

  return <div className="accordion">
    {renderedItems}
  </div>;
};

export default Accordion;
