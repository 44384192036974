import React from 'react';
import { useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";
import image1 from '../../../assets/images/homepage/four-1.webp';
import image2 from '../../../assets/images/homepage/four-2.webp';
import arrow from '../../../assets/images/arrow-orange.svg';

import './style.scss';

function FourDisplay() {
  const { t } = useTranslation();

  return (
    <div className='four-display'>
      <div className='container'>
        <div className='main-title'>
          {t('main.four.text-0')}
        </div>
        <div className='main-text'>
          {t('main.four.text-1')}
        </div>
      </div>
      <div className='content'>
        <div className='line'>
          <div className='item'>
              <div className='item-title'>{t('main.four.text-2')}</div>
              <div className='item-text'>{t('main.four.text-3')}</div>
          </div>
          <div className='item item--image'>
              <img src={image1} alt='' />
          </div>
          <div className='item'>
              <div className='item-title'>{t('main.four.text-4')}</div>
              <div className='item-text'>{t('main.four.text-5')}</div>
          </div>
        </div>
        <div className='line-bg'>
          <div className='line'>
            <div className='item item--image'>
                <img src={image2} alt='' />
            </div>
            <div className='item'>
                <div className='item-title'>{t('main.four.text-6')}</div>
                <div className='item-text'>{t('main.four.text-7')}</div>
            </div>
            <div className='item last'>
              <Button text={t('main.four.text-8')} icon={arrow} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FourDisplay;