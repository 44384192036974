import React, { useState } from 'react';
import { Trans, useTranslation } from "react-i18next";
import Button from '../../GlodalComponents/Button/Button';

import image0 from '../../../assets/images/services/p0.webp';
import image1 from '../../../assets/images/services/p1.webp';
import image2 from '../../../assets/images/services/p2.webp';
import image3 from '../../../assets/images/services/p3.webp';
import image4 from '../../../assets/images/services/p4.webp';
import image5 from '../../../assets/images/services/p5.webp';
import image6 from '../../../assets/images/services/p6.webp';
import image7 from '../../../assets/images/services/p7.webp';
import image8 from '../../../assets/images/services/p8.webp';
import image9 from '../../../assets/images/services/p9.webp';
import arrow from '../../../assets/images/arrow-orange.svg';

import './style.scss';

const PaymentMethods = () => {
  const { t } = useTranslation();

  const images = [image0, image1, image2, image3, image4, image5, image6, image7, image8, image9];

  const items = t('third-4-table', { returnObjects: true });
  const titles = Object.values(items[0]);
  const columnsTitles = Object.keys(items[0]).slice(0, -1);
  const columns = items.slice(1);

  return (
    <div className='payment-method'>
      <Trans><div className='main-title'>{t('third-4-title')}</div></Trans>
      <div className='table-wrap'>
        <div className='table-grid'>
          <div className='head'>
            <div className='container'>
              {titles.map((item, index) => (
                  <div className='item' key={index}>
                  {t(`${item}`)}
                </div>
              ))}
            </div>
          </div>
          <div className='body'>
            {columns.map((item, index) => (
              <div className='row' key={index}>
                <div className='container'>
                  <div className='item img'>
                    <img src={images[index]} alt='' />
                  </div>
                  {columnsTitles.map((title, cellIndex) => (
                    <div className='item' key={cellIndex}>
                      {t(`${item[title]}`)}
                    </div>
                  ))}
                  <div className='item'>
                    <Button text={t('third-4-btn')} icon={arrow} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='main-text'>{t('third-4-text')}</div>
    </div>
  );
};

export default PaymentMethods;
