import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Fade } from 'react-reveal';
import Button from '../../GlodalComponents/Button/Button';
import arrow from '../../../assets/images/arrow-right.svg';

import './style.scss';


const TableTabs = () => {
  const [activeColumn, setActiveColumn] = useState(1);
  const { t } = useTranslation();

  const data = t('tableTabs', { returnObjects: true });
  const titles = Object.values(data[0]);
  const columns = Object.keys(data[0]);

  return (
    <div className='table-tabs'>
      <Fade bottom>
        <div className="responsive-table">
          <div className="tabs">
            {titles.map((item, index) => (
              <button
                key={index}
                className={`tab ${activeColumn === index ? 'active' : ''}`}
                onClick={() => setActiveColumn(index)}
              >
                {item}
              </button>
            ))}
          </div>

          <div className='t-table'>
            <div className='t-head'>
              <div className='t-container'>
                {titles.map((item, index) => (
                  <div key={index} className={`table-row block-${index}`}>
                    {item}
                  </div>
                ))}
              </div>
            </div>
            <div className='t-body'>
              {data.map((row, rowIndex) => (
                <div className={`t-row ${rowIndex === 0 ? 'first-row' : ''}`} key={rowIndex}>
                  <div className='t-container'>
                    {columns.map((column, index) => (
                      <div key={index} className={`table-row block-${index} ${index === activeColumn ? 'active-column' : ''}`}>
                        {row[column]}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Fade>

      <div className='btn-wrap'>
        <div className='container'>
          <Button text={t('main.first.btn')} icon={arrow} />
        </div>
      </div>
    </div>
  );
};

export default TableTabs;